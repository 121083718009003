import React from "react";
import learn_with_us from '../public/images/learnwithus-removebg-preview.png'
import growth_with_us from '../public/images/growwithus-removebg-preview.png'
import intern_with_us from '../public/images/internwithus-removebg-preview.png'
import { useEffect } from "react";
const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top
      }, []);
    
    return (
        <div>
             <h1 className="text-center font-bold text-center my-2 text-5xl py-5">Our Vision</h1>
            <div className="lg:w-[80%] flex justify-between mx-auto items-center my-5 lg:flex-row flex-col w-[95%] gap-y-5">
                <div className="lg:w-[30%] w-[95%] p-10 shadow-2xl bg-white h-[20vh] rounded">

                </div>
                <div className="px-2 lg:w-[50%] w-[95%] text-justify text-xl">
                    <p>
                        We are a technology company on a mission to equip students with relevant skills & practical
                        exposure to help them get the best possible start to their careers.
                        Imagine a world full of freedom and possibilities. A world where you can discover your passion and turn it into your career.
                        A world where you graduate fully assured, confident, and prepared to stake a claim on your place in the world.
                    </p>
                </div>
            </div>
            <div className="flex justify-between lg:w-[80%] mx-auto my-20 lg:flex-row flex-col w-[95%] gap-y-10">
                <div class=" flex items-center flex-col lg:w-[30%] w-full">
                    <img src={learn_with_us} className="w-[300px] h-[250px]"></img>

                    <br />
                    <p class="font-bold text-xl">Learn With Us </p>
                    <br />
                    <p class="about-shortparagraphs text-center">
                    Learn new-age skills on the go with our short-term online trainings. Grow with us, Intern with us!
                    </p>
                </div>

                <div class=" flex items-center flex-col lg:w-[30%] w-full">
                    <img src={growth_with_us} class="w-[300px] h-[250px]"></img>

                    <br />
                    <p class="font-bold text-xl">Grow With Us </p>
                    <br />
                    <p class="about-shortparagraphs text-center">
                    Expand your skills and career opportunities with hands-on experience and guidance from industry experts.
                    </p>
                </div>

                <div class=" flex items-center flex-col lg:w-[30%] w-full">
                    <img src={intern_with_us} class="w-[300px] h-[250px]"></img>

                    <br />
                    <p class="font-bold text-xl">Intern With Us </p>
                    <br />
                    <p class="about-shortparagraphs text-center">
                        Gain hands-on experience, hone your skills, and step confidently into your career.
                    </p>
                </div>
            </div>

        </div>
    )
}


export default AboutPage