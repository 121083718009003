import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAuth } from "../AuthContext";
import GoogleLoginButton from "./GoogleLogin";
import LoaderComponent from "./LoaderComponent";
import header_token from "../Header_Token";
const MySwal = withReactContent(Swal);

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { setAuthData } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for showing password

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, formData, {
        headers: {
          "Content-Type": "application/json",
          'X-Frontend-Secret': header_token
        },
      });

      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user_email", response.data.user_email);
        localStorage.setItem("user_name", response.data.user_name);
        // Update the context
        setAuthData({ token: response.data.token, userEmail: response.data.user_email, userName: response.data.user_name });
        setLoading(false);
        await MySwal.fire({
          title: 'Success!',
          text: 'Logged in successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        });

        navigate("/"); // Redirect to home or another page
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleGoogleSuccess = async (response) => {
    const tokenId = response.credential;

    try {
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/signin_with_google`,
        { tokenId },
        { headers: { 'X-Frontend-Secret': header_token } }
      );

      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user_email", res.data.user_email);
      localStorage.setItem("user_name", res.data.user_name);

      setAuthData({
        token: res.data.token,
        userEmail: res.data.user_email,
        userName: res.data.user_name
      });
      setLoading(false);
      MySwal.fire('Success', 'Logged in successfully!', 'success');
      navigate("/");
    } catch (error) {
      setLoading(false);
      toast.error("Google Sign-In failed");
    }
  };

  const handleError = () => {
    toast.error('Login Failed');
  };

  return (
    <div className="flex flex-col items-center justify-center p-4 animated-border mx-auto lg:w-[35%] my-10 rounded-lg shadow-2xl text-white w-[98%]">
      <LoaderComponent isLoading={loading} />
      <h2 className="text-2xl font-semibold text-center mb-6">Login</h2>
      <div>
        <GoogleLoginButton onSuccess={handleGoogleSuccess} onError={handleError}></GoogleLoginButton>
      </div>

      <div className="flex w-[92%] text-white items-center my-4">
        <div className="bg-white p-[0.5] h-[1px] w-full"></div>
        <p className="px-2 text-xl">OR</p>
        <div className="bg-white p-[0.5] h-[1px] w-full"></div>
      </div>
      <form onSubmit={handleSubmit} className="w-full rounded-lg shadow-md p-6">
        {/* Email Field */}
        <div className="mb-4">
          <label className="block mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.email}
            onChange={handleChange}
            placeholder="you@example.com"
          />
        </div>

        {/* Password Field */}
        <div className="relative mb-4"> {/* Added relative position here */}
          <label className="block mb-2" htmlFor="password">
            Password
          </label>
          <input
            type={showPassword ? "text" : "password"} // Toggle between text and password
            name="password"
            id="password"
            className="w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10" // Added pr-10 for padding right
            value={formData.password}
            onChange={handleChange}
            placeholder="Your password"
          />
          <span
            className={`absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer top-7`}
            onClick={() => setShowPassword(!showPassword)} // Toggle show password
          >
            {showPassword ? (
               <FaEyeSlash className="text-white " />
              ) : (
              <FaEye className="text-white " />
            )}
          </span>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200 font-bold"
        >
          Login
        </button>
        <div className="flex lg:gap-x-5 mt-4 gap-x-2 lg:text-lg text-sm">
          <p>Don't have an account?</p>
          <Link to="/signup" className="text-blue-600">Signup</Link>
        </div>
        <div className="flex lg:gap-x-5 mt-4 gap-x-2 lg:text-lg text-sm">
          <p>Forgot Password?</p>
          <Link to="/forgot_password/" className="text-blue-600">Reset Password</Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
