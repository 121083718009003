import React, { useState, useEffect } from "react";
import contact_us_illus from '../public/images/contact_us_banner.png';
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from "../Components/LoaderComponent";
import header_token from "../Header_Token";
const ContactPage = () => {
  const [loading ,setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: ""
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);

  // Regex validators
  const nameRegex = /^[a-zA-Z\s]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const mobileRegex = /^[0-9]{10}$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Real-time validation
    if (name === "name" && !nameRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Please enter a valid name (letters and spaces only).",
      }));
    } else if (name === "name") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }

    if (name === "email" && !emailRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address.",
      }));
    } else if (name === "email") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    if (name === "mobile" && !mobileRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Please enter a valid 10-digit mobile number.",
      }));
    } else if (name === "mobile") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if there are validation errors before submission
    if (errors.name || errors.email || errors.mobile) {
      toast.error("Please fix the validation errors before submitting.");
      return;
    }

    try {
      setLoading(true)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/submit_contact_form`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'X-Frontend-Secret': header_token
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setLoading(false)
        Swal.fire({
          title: 'Success!',
          text: 'Thanks for connecting with us , Our team will reach you soon',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        // Reset form data after successful submission
        setFormData({
          name: "",
          email: "",
          mobile: "",
          message: ""
        });
      } else {
        setLoading(false)
        toast.error("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      setLoading(false)
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="lg:w-[80%] mx-auto my-24 relative">
     <LoaderComponent isLoading={loading}></LoaderComponent>
      <div className="absolute lg:w-[20%] lg:h-[20%] lg:left-[20%] h-[40%] w-[40%] left-[5%] rotate-[82.42deg] custom-bg-ilus"></div>
      <div className="absolute lg:w-[20%] lg:h-[20%] lg:right-[10%] lg:bottom-[5%] h-[5%] w-[5%] bottom-[5%] right-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
      <div className="absolute lg:w-[10%] lg:h-[10%] lg:left-[5%] lg:bottom-[5%] w-[30%] h-[30%] bottom-[15%] left-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
      <div className="flex lg:flex-row flex-col-reverse justify-between items-center px-5 gap-y-5">
        <div className="lg:w-[50%] w-[95%]">
          <div className="py-5">
            <form
              className="flex flex-col gap-y-5 lg:w-[70%] w-full py-2 border border-white rounded-xl p-5"
              onSubmit={handleSubmit}
            >
              <h1 className="text-2xl my-2 font-bold text-center">Contact Us !</h1>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="bg-transparent border border-gray-300 p-2 rounded"
                required
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Address"
                className="bg-transparent border border-gray-300 p-2 rounded"
                required
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              <input
                type="tel"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="bg-transparent border border-gray-300 p-2 rounded"
                placeholder="Mobile Number"
                required
              />
              {errors.mobile && <p className="text-red-500 text-sm">{errors.mobile}</p>}
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={5}
                className="bg-transparent border border-gray-300 rounded p-2"
                placeholder="Write your message..."
                required
              ></textarea>
              <button
                type="submit"
                className="contact-card-button p-2 rounded"
              >
                Contact Us
              </button>
            </form>
          </div>
        </div>
        <div className="lg:w-[50%]">
          <img src={contact_us_illus} alt="Contact Us Illustration"></img>
        </div>
      </div>
      {/* <div className="text-2xl mx-auto text-center my-5 px-5">
        
         <p> <b>Contact Address:</b> Shanti Bhavan Dubey Marg, Ramnagar Sirsa  Meja  Prayagraj , UP India , 212305 </p>
        </div> */}
    </div>
  );
};

export default ContactPage;
