import React from 'react';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import OfferLetter from './OfferLetter';
import header_token from '../Header_Token';

const options = {
  filename: 'OfferLetter.pdf',
  method: 'save',
  resolution: Resolution.HIGH,
  page: {
    margin: Margin.SMALL,
    format: 'letter',
    orientation: 'portrait',
  },
  canvas: {
    mimeType: 'image/jpeg',
    qualityRatio: 1,
  },
  overrides: {
    pdf: {
      compress: true,
    },
    canvas: {
      useCORS: true,
    },
  },
};

const getTargetElement = () => document.getElementById('certificate-content');

const OfferLetterPdf = () => {
  const { email, internship_id } = useParams();
  const [certificateData, setCertificateData] = useState(null);

  useEffect(() => {
    const fetchOfferLetter = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/offer_letter/${email}/${internship_id}`,{headers:{'X-Frontend-Secret': header_token}});
        setCertificateData(response.data.certificate_data);
      } catch (err) {
        toast.error(err.response?.data || "Error fetching certificate data.");
      }
    };

    fetchOfferLetter();
  }, [email, internship_id]);

  return (
    <div>
     <div className='my-5 mx-auto w-fit'>
        <button onClick={() => generatePDF(getTargetElement, options)} className='animated-border px-5 text-xl'>Download Offer Letter</button>
        </div>
      <div
        id="certificate-content"
        style={{
          border: '2px solid #000',
          width: '100%',
          maxWidth: '800px',
          margin: '50px auto',
          textAlign: 'justify',
          boxSizing: 'border-box',
        }}
        className="text-black text-lg"
      >
        <OfferLetter certificateData={certificateData}></OfferLetter>
      </div>
    </div>
  );
};

export default OfferLetterPdf;
