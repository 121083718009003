import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import header_token from "../Header_Token";
import LoaderComponent from "./LoaderComponent";
const VerifyEmail = () => {
  const [loading , setLoading] = useState(false)
  const { email, token } = useParams();
  const [isVerified, setIsVerified] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        setLoading(true)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/verify_mail`, 
          {
            email: email,
            token: token,
          }, 
          {
            headers: { 'X-Frontend-Secret': header_token }
          }
        );
        setIsVerified(true);
        setLoading(false)
        Swal.fire("Success", response.data, "success").then(() => {
          navigate("/login");  // Redirect to login page after success
        });
      } catch (error) {
        setLoading(false)
        setIsVerified(false);
        toast.error(error.response?.data || "Verification failed.");
      }
    };

    verifyEmail();
  }, [email, token, navigate]);

  return (
    <div className="min-h-screen">
      <LoaderComponent isLoading={loading}></LoaderComponent>
      {isVerified === null ? <p>Verifying...</p> : null}
    </div>
  );
};

export default VerifyEmail;
