import React from 'react';
import { Helmet } from 'react-helmet';
import codisher_logo from '../public/images/codisher_logo_3.png'
const Certificate = ({ certificateData }) => {
  // Check if data is still being loaded
  if (!certificateData) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container bg-white text-black relative" style={{ border: '1px solid rgb(17, 0, 92)' }}>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <img
        src={codisher_logo} // Path to your logo
        alt="Watermark"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-15 w-1/3 z-1"
      />
      {/* Background images */}
      <div className="absolute top-0 left-0 z-0">
        <img src={`${process.env.REACT_APP_API_URL}/public/images/certificate_left_corner.png`} className="h-[60%] w-[80%]" alt="left corner" />
      </div>
      <img src={`${process.env.REACT_APP_API_URL}/public/images/right_croner_certifcate.png`} className="absolute top-0 right-0 z-0 h-[35%] w-[35%]" alt="right corner" />
      <div className="absolute bottom-0 right-0 z-0 rotate-180">
        <img src={`${process.env.REACT_APP_API_URL}/public/images/certificate_left_corner.png`} className="h-[60%] w-[80%]" alt="left corner bottom" />
      </div>
      <img src={`${process.env.REACT_APP_API_URL}/public/images/right_croner_certifcate.png`} className="absolute bottom-0 left-0 z-0 rotate-180 h-[35%] w-[35%]" alt="right corner bottom" />
      <img src={`${process.env.REACT_APP_API_URL}/public/images/msme-logo.png`} className="absolute top-[50px] right-[50px] z-0 w-[8rem] h-[6rem]" alt="msme logo" />

      {/* Content section */}
      <div className="absolute z-10 w-full top-0 left-0 flex flex-col items-center" style={{ marginTop: '15%' }}>
        <img src="https://codisher.com/public/images/codisher_logo.png" className="w-[10rem] h-[10rem]" alt="codisher logo" />
        <h1 className="text-2xl font-bold mt-7 underline">TO WHOM SO IT MAY CONCERN</h1>
      </div>

      <div className="absolute z-20 w-full" style={{ top: '35%', paddingLeft: '35px', paddingRight: '35px' }}>
        <div className="flex justify-between">
          <p className="font-bold">Date: {certificateData.end_date}</p>
          <p className="font-bold">CD/CT/{certificateData.offer_letter_id}</p>
        </div>
        <div className="mt-10">
          <p>This is to certify that <span className="font-bold uppercase">{certificateData.name}</span> is working as <b>{certificateData.internship_name} Intern </b> associated with "CODISHER" the period of one month from {certificateData.start_date} to {certificateData.end_date}.</p>
          <br></br>
          <p>Throughout the duration of the internship, <span className="font-bold uppercase">{certificateData.name}</span> has consistently demonstrated an exceptional level of responsibility, sincerity, and a true eagerness to expand his knowledge and embrace new tasks and challenges. Notably, his coordination and communication skills are truly outstanding, and his</p>
          <br></br>
          <p className='font-bold'>We wish all the very best for your future.</p>
        </div>
      </div>

      {/* Stamps and QR code */}
      <div className="absolute bottom-[10%] left-[10%] z-20">
        <img src={`${process.env.REACT_APP_API_URL}/public/images/certificate_stamp_final.png`} className="h-[8rem] w-[8rem]" alt="stamp" />
      </div>
      <div className="absolute bottom-[7%] right-[15%] z-20">
        <img src={`${process.env.REACT_APP_API_URL}${certificateData.internship_qr_code}`} className="h-[10rem] w-[10rem]" alt="qr code" />
      </div>
    </div>
  );
};

export default Certificate;
