import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import LoaderComponent from "./LoaderComponent";
import header_token from "../Header_Token";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError("");
    }

    try {
      setLoading(true);
      const client_verification_url = `${window.location.protocol}//${window.location.host}/update_password`;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/forgotpassword`, {
        email,
        client_verification_url,
      }, {
        headers: {
          "Content-Type": "application/json",
          'X-Frontend-Secret': header_token,
        },
      });

      if (response.status === 200) {
        Swal.fire('Success', 'Password Reset Mail sent successfully!', 'success');
        navigate('/login')
      } else {
        toast.error("Failed to send verification email.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while sending the request.");
    }
  };

  return (
    <div className="min-h-[60vh]">
    <div className="flex flex-col items-center justify-center p-4 animated-border mx-auto lg:w-[35%] my-10 rounded-lg shadow-2xl text-white w-[98%]">
      <LoaderComponent isLoading={loading} />
      <h2 className="text-2xl font-semibold text-center mb-6">Forgot Password</h2>
      <form onSubmit={handleSubmit} className="w-full rounded-lg shadow-md p-6">
        <div className="mb-4">
          <label className="block mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="you@example.com"
          />
          {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200 font-bold"
        >
          Send Reset Link
        </button>
      </form>
    </div>
    </div>
  );
};

export default ForgotPassword;
