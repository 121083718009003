import React from "react";
import { FaRegClock } from "react-icons/fa";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../AuthContext";
import Swal from "sweetalert2";
import header_token from "../../Header_Token";
import LoaderComponent from "../../Components/LoaderComponent";

const InternshipBanner = ({ page_id, onApplyClick }) => {
    const [internshipName, setInternshipName] = useState('')
    const [internshipThumbnail, setInternshipThumbnail] = useState('')
    const [intenrhsipId, setInternshipId] = useState('')
    const [user_mail, setuser_mail] = useState('')
    const [user_name , setuser_name] = useState('')
    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(false)
    const [amount, setamount] = useState(99);
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top
    }, []);

    useEffect(() => {
        const fetchInternshipData = async () => {
            try {
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/internship/${page_id}`, { headers: { 'X-Frontend-Secret': header_token } });
                setLoading(false)
                setInternshipName(response.data.internship_data.name)
                setInternshipThumbnail(response.data.internship_data.courseimage)
                setInternshipId(response.data.internship_data.courseID)
            } catch (err) {
                setLoading(false)
                toast.error(err.message);
            }
        };

        fetchInternshipData();
    }, [page_id]); // Fetch new data when the id changes
    const { authData } = useAuth();
    useEffect(() => {
        // Ensure authData is defined before checking for token
        if (authData) {
            setLogin(!!authData.token); // Set to true if token exists
            setuser_mail(authData.userEmail)
            setuser_name(authData.userName)
        } else {
            setLogin(false); // Set to false if authData is null
        }
    }, [authData]);

    const apply_internship = () => {
        if (login) {
            //onApplyClick(internshipName, intenrhsipId, user_mail); // Pass data to parent component
            // handlePayment Function
            setLoading(true)
            handlePayment()
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please Login first.",
                footer: '<a href="/login">Login</a>',
            });
        }
    };

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const handlePayment = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/payment/order`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    'X-Frontend-Secret': header_token
                },
                body: JSON.stringify({
                    amount
                })
            });

            const data = await res.json();
            setLoading(false)
            handlePaymentVerify(data.data)
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    // handlePaymentVerify Function
    const handlePaymentVerify = async (data) => {
        const scriptLoaded = await loadRazorpayScript();
        if (!scriptLoaded) {
            console.error("Razorpay SDK failed to load. Are you online?");
            return;
        }
        const options = {
            key: process.env.REACT_APP_RAZORPAY_ID_KEY,
            amount: amount,
            currency: 'INR',
            name: internshipName,
            description: `${internshipName} Intern`,
            order_id: data.id,
            prefill: {
                email: user_mail,
            },
            notes: {
                name: user_name
            },
            handler: async (response) => {
                // console.log("response", response)
                try {
                    setLoading(true)
                    const res = await fetch(`${process.env.REACT_APP_API_URL}/payment/verify`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            'X-Frontend-Secret': header_token
                        },
                        body: JSON.stringify({
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature,
                            user_email: user_mail,
                            course_id: intenrhsipId,
                            course_name: internshipName
                        })
                    })
                    const verifyData = await res.json();

                    if (verifyData.message) {
                        setLoading(false)
                        Swal.fire({
                            title: verifyData.message.title,
                            text: verifyData.message.text,
                            icon: "success"
                          });
                    }
                } catch (error) {
                    setLoading(false)
                    console.log(error);
                    toast.error("Something went wrong")
                }
            },
            theme: {
                color: "#5f63b8"
            }
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    }


    return (
        <div className="lg:w-[80%] mx-auto my-10 w-[95%] relative">
            <LoaderComponent isLoading={loading} />
            <div className="absolute lg:w-[20%] lg:h-[20%] lg:left-[20%] h-[40%] w-[40%] left-[5%]  rotate-[82.42deg] custom-bg-ilus"></div>
            <div className="absolute lg:w-[20%] lg:h-[20%] lg:right-[10%] lg:bottom-[5%] h-[5%] w-[5%] bottom-[5%] right-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
            <div className="absolute lg:w-[10%] lg:h-[10%] lg:left-[5%]   lg:bottom-[5%] w-[30%] h-[30%] bottom-[15%] left-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
            <div className="flex justify-between items-center bg-[#eeeeee1f] rounded-md lg:flex-row flex-col-reverse py-16">

                <div className="rounded   font-bold  lg:px-10 lg:w-[50%]  text-center lg:text-justify">
                    <p className="lg:py-2 lg:text-5xl text-3xl">Start your learning as </p>
                    <p className="lg:py-2 py-1 lg:text-5xl text-3xl">{internshipName}</p>
                    <div className=" w-fit mx-auto lg:mx-0 mt-10 py-3 bg-gradient-to-br from-[#1d8af7] via-[#1d8af7] via-50% to-[#f7861d] shadow-[1px_-1px_8px_0_rgba(247,134,29,0),-1px_1px_8px_0_rgba(29,138,247,0)] transition-all duration-300 ease-linear text-[#f7fafc] font-bold text-center text-lg px-8 rounded py-1 hover:shadow-[0px_4px_15px_0px_rgba(29,138,247,0.6),0px_-4px_15px_0px_rgba(247,134,29,0.6)] hover:scale-105 cursor-pointer" onClick={apply_internship}>
                        <p>Apply Now</p>
                    </div>
                </div>

                <div className="lg:w-[40%]">
                    <img src={`${process.env.REACT_APP_API_URL}${internshipThumbnail}`}></img>
                </div>
            </div>

        </div>
    )
}

export default InternshipBanner