import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import google_logo from "../public/images/google-logo.png"; // Adjust the path accordingly


const GoogleLoginButton = ({ onSuccess, onError }) => {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <GoogleLogin
                onSuccess={onSuccess}
                onError={onError}
                render={({ onClick }) => (
                    <button 
                        onClick={onClick} 
                        className="flex items-center justify-center bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200 transform hover:scale-105"
                        style={{ border: '1px solid #ddd', boxShadow: '0 2px 10px rgba(0,0,0,0.2)' }}
                    >
                        <img src={google_logo} alt="Google logo" className="h-5 w-5 mr-2" />
                        <span className="font-bold">Sign in with Google</span>
                    </button>
                )}
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;


