import React from "react";
import { useEffect } from "react";
import AllBlogs from "./AllBlogs";


const BlogsPage = () =>
{
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top
    }, []);
    return(
        <div className="min-h-[60vh]">
            <AllBlogs></AllBlogs>
        </div>
    )
}


export default BlogsPage