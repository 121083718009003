import { useEffect } from 'react';

const DrawerOverlay = ({ isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Disable scroll
    } else {
      document.body.style.overflow = ''; // Re-enable scroll
    }

    return () => {
      document.body.style.overflow = ''; // Cleanup on unmount
    };
  }, [isOpen]);

  return isOpen ? (
    <div className="fixed inset-0 bg-white opacity-50 z-40 pointer-events-none"></div>
  ) : null;
};

export default DrawerOverlay;
