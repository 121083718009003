// Dashboard.js
import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import LoaderComponent from "../Components/LoaderComponent";
import InternTaskForm from "./InternTaskForm";
import header_token from "../Header_Token";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const { authData, authLoading } = useAuth(); // Destructure authLoading

  const [internships, setInternships] = useState([]);
  const [userMail, setUserMail] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false); // Existing state for internships loading
  const [internshipId, setInternshipId] = useState("");
  const [showForm, setShowForm] = useState(false);

  const handleOpenForm = (internship_id) => {
    setInternshipId(internship_id);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  // Authentication Check
  useEffect(() => {

    if (!authLoading) {
      if (authData && authData.token && authData.userEmail) {
        setIsLoggedIn(true);
        setUserMail(authData.userEmail);
      } else {
        setIsLoggedIn(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please login first.",
          footer: '<a href="/login">Login</a>',
        }).then(() => {
          navigate("/login");
        });
      }
    }
  }, [authData, authLoading, navigate]);

  // Fetch Internships
  useEffect(() => {
    const fetchInternships = async () => {
      if (!userMail) return; // Wait until userMail is set

      setLoading(true); // Set loading state
  

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/registered_internships/${userMail}`,
          { headers: { "X-Frontend-Secret": header_token } }
        );
        setInternships(response.data.user.internships);
       
      } catch (err) {
        toast.error("Can't fetch dashboard");
      } finally {
        setLoading(false);
      }
    };

    if (isLoggedIn) {
      fetchInternships();
    }
  }, [userMail, isLoggedIn]);

  if (authLoading) {
    // Show loader while authentication is being checked
    return <LoaderComponent />;
  }

  if (!isLoggedIn) {
    // While redirecting, you can optionally show a placeholder or null
    return null;
  }

  return (
    <div>
      {showForm && (
        <InternTaskForm
          internship_id={internshipId}
          user_mail={userMail}
          onClose={handleCloseForm}
        />
      )}
      <p className="text-center text-5xl mt-5">Internships</p>
      {loading ? (
        <LoaderComponent />
      ) : (
        <div className="flex flex-wrap lg:space-x-10 lg:space-y-0 space-y-10 my-5 w-[80%] mx-auto items-center min-h-[60vh]">
          {internships.length > 0 ? (
            internships.map((internship) => (
              <div
                key={internship._id}
                className="w-fit p-5 border-2 border-gray-300 flex flex-col space-y-8 my-5 shadow-2xl rounded-lg "
              >
                <p>
                  <span className="font-bold">Internship Domain :</span>{" "}
                  {internship.internship_domain_name}
                </p>
                {internship.payment ? (
                  <>
                    <a
                      href={`/offer_letter/${userMail}/${internship._id}`}
                      className="text-blue-600"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Offer Letter
                    </a>
                    <p>
                      <span className="font-bold">Allotted Tasks:</span>
                      <ul className="list-decimal ml-8">
                        <li>{internship.internship_task1}</li>
                        <li>{internship.internship_task2}</li>
                      </ul>
                      <p className="text-green-500 my-4 text-justify">Note: You need to complete the above given tasks and <br></br>submit it using the below Submit Task button</p>
                    </p>
                    {internship.internship_task_submmited ? (
                      <p className="text-green-400">Task Submitted</p>
                    ) : (
                      <>
                        <p
                          className="cursor-pointer font-bold bg-blue-800 w-fit px-4 py-2 rounded"
                          onClick={() => handleOpenForm(internship._id)}
                        >
                          Submit Task
                        </p>
                        {/* <p>
                          <span className="font-bold">Certificate :</span> Issued
                          after task submission
                        </p> */}
                      </>
                    )}
                    {internship.internship_certificate ? (
                      <a
                        href={`/certificate/${userMail}/${internship._id}`}
                        className="text-blue-600 cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Certificate
                      </a>
                    ) : (
                      <p>
                        <span className="font-bold">Certificate :</span> Issued
                        after task verification
                      </p>
                    )}
                  </>
                ) : (
                  <h1>Payment Status: Pending</h1>
                )}
              </div>
            ))
          ) : (
            <div className="text-3xl lg:w-[25%] w-fit p-10 text-justify rounded-lg shadow-2xl my-36">
              <p>Sorry! You are not enrolled in any internship</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
