import React from "react";

import HeroSection from "./Components/HeroSection";
import TagLabel from "./Components/TagLabel";
import AboutSection from "./Components/AboutSection";
import InternshipSection from "../Internship/InternshipSection";
import ReviewSection from "./Components/ReviewSection";
import BlogSection from "./Components/BlogSection";
import DiscussSection from "./Components/DiscussSection";
import { useEffect } from "react";
const Home =()=>
{
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top
    }, []);
  
    return(
        <div>
                        <HeroSection/> 
                        <TagLabel/>
                        <AboutSection/>
                       <InternshipSection/>
                        <ReviewSection/>
                        <BlogSection/>
                        <DiscussSection/> 
        </div>
        
    )
}


export default Home