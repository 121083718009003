import React from "react";



const NotFound = () =>
{
    return(
        <div className="text-5xl text-center font-bold min-h-screen mt-36">
                <p>404 Page Not Found</p>
        </div>
    )
}

export default NotFound