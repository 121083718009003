import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import header_token from '../Header_Token';
import LoaderComponent from './LoaderComponent';
const InternshipDetails = () => {
    const [loading ,setLoading] = useState(true)
    const [certificateData, setCertificateData] = useState(null);
    const { email, internship_id } = useParams()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/verify_intern/${email}/${internship_id}`, {
                    headers: { 'X-Frontend-Secret': header_token }
                });
                setLoading(false)
                setCertificateData(response.data.certificate_data);
            } catch (error) {
                setLoading(false)
                toast.error('Cannot Fetch Details')
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [email, internship_id]);
   
    if (certificateData == null || undefined) return <div><LoaderComponent isLoading={loading}></LoaderComponent></div>;

    return (
        <div className="mx-auto shadow-2xl rounded-sm p-5 border-2 border-gray-400 my-24 flex flex-col lg:w-[35%] gap-y-4 text-xl w-[90%]">
             <h1 className="text-center font-bold text-2xl text-center">Internship Details</h1>
            <table className="min-w-full table-auto text-left">
           
                <tbody>
                    <tr>
                        <th className="px-4 py-2 border-r font-semibold">Name</th>
                        <td className="px-4 py-2">{certificateData.intern_name}</td>
                    </tr>
                    <tr>
                        <th className="px-4 py-2 border-r font-semibold">Position</th>
                        <td className="px-4 py-2">{certificateData.internship_name}</td>
                    </tr>
                    <tr>
                        <th className="px-4 py-2 border-r font-semibold">Start Date</th>
                        <td className="px-4 py-2">{certificateData.start_date}</td>
                    </tr>
                    <tr>
                        <th className="px-4 py-2 border-r font-semibold">End Date</th>
                        <td className="px-4 py-2">{certificateData.end_date}</td>
                    </tr>
                    <tr>
                        <th className="px-4 py-2 border-r font-semibold">Status</th>
                        <td className="px-4 py-2">{certificateData.internship_status}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default InternshipDetails;
