import React, { useEffect, useState } from "react";
import InternshipCard from "./Components/InternshipCard";
import { toast } from "react-toastify";
import header_token from "../Header_Token";
import axios from "axios";
const InternshipSection = () => {
    const [internships, setInternships] = useState([]);
    useEffect(() => {
        const fetchInternships = async () => {
            try {
                // Define your custom secret
                const secretValue = 'YourSecretValue123';

                // Axios request with custom headers
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/internship`, {
                    headers: {
                        'X-Frontend-Secret': header_token,
                    },
                });

                // Check if the response is okay and set the data
                setInternships(response.data);
            } catch (err) {
                // Handle any errors and show toast notification
                toast.error(err.message);
            }
        };

        fetchInternships();
    }, []);

    return (
        <div className="py-10">
            <h1 className="text-5xl font-extrabold text-center my-5 py-10">Internships</h1>
            <div className="flex flex-wrap mx-auto lg:w-[80%] px-10 justify-between lg:gap-y-16 gap-y-10 gap-x-5">
                {internships.map((internship) => (
                    <InternshipCard 
                    key = {internship.courseID}
                    internship_name = {internship.name}
                     internship_thumbnail = {internship.courseimage} 
                     internship_page_id = {internship.page_name}
                     />
                ))}
            </div>
        </div>
    );
};

export default InternshipSection;

