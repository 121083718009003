import React from "react";
import { useEffect } from "react";
const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top
      }, []);
    
    return (
        <div className="lg:w-[60%] mx-auto my-10 w-[90%]">
            <h1 className="text-3xl font-bold text-center mb-6">Terms and Conditions for Codisher Internships</h1>
            <p className="mb-4">
                Welcome to the Codisher internship program. By participating in our internships, you agree to comply with and be bound by the following terms and conditions.
            </p>

            <h2 className="text-2xl font-semibold mt-6">1. Acceptance of Terms</h2>
            <p className="mb-4">
                By applying for or participating in a Codisher internship, you confirm your acceptance of these terms and conditions. If you do not agree, please refrain from applying.
            </p>

            <h2 className="text-2xl font-semibold mt-6">2. Internship Duration</h2>
            <p className="mb-4">
                Internships at Codisher typically last for a duration of [insert duration]. Specific details will be communicated upon selection.
            </p>

            <h2 className="text-2xl font-semibold mt-6">3. Responsibilities</h2>
            <p className="mb-4">
                As an intern, you are expected to:
                <ul className="list-disc list-inside ml-5">
                    <li>Complete assigned tasks on time;</li>
                    <li>Attend scheduled meetings;</li>
                    <li>Maintain professionalism and confidentiality.</li>
                </ul>
            </p>

            <h2 className="text-2xl font-semibold mt-6">4. Compensation</h2>
            <p className="mb-4">
                Interns at Codisher may receive compensation in the form of a stipend, which will be outlined in the internship offer.
            </p>

            <h2 className="text-2xl font-semibold mt-6">5. Termination</h2>
            <p className="mb-4">
                Codisher reserves the right to terminate the internship at any time for violations of these terms or for misconduct.
            </p>

            <h2 className="text-2xl font-semibold mt-6">6. Changes to Terms</h2>
            <p className="mb-4">
                Codisher may update these terms and conditions at any time. Interns will be notified of significant changes.
            </p>

        </div>
    );
};

export default TermsAndConditions;
