import React from 'react';

const ShippingDeliveryPolicy = () => {
  return (
    <section id="shipping-delivery-policy" className="p-8  lg:w-[80%] mx-auto my-24 w-[90%]">
      <h2 className="text-3xl font-bold mb-4">Shipping & Delivery Policy</h2>
      
      <p className="mb-4">
        At Codisher, we are committed to delivering essential documents like offer letters and certificates promptly.
      </p>
      
      <h3 className="text-xl font-semibold mb-2">Offer Letter Delivery</h3>
      <p className="mb-4">
        Upon successful payment for your internship, your offer letter will be delivered electronically to your registered email address.
      </p>
      
      <h3 className="text-xl font-semibold mb-2">Certificate Delivery</h3>
      <p className="mb-4">
        After successfully completing your internship tasks, you will receive your certificate within one month. If you face any challenges completing tasks, our team will provide the necessary support to ensure your success.
      </p>
      
      <h3 className="text-xl font-semibold mb-2">Support During Internship</h3>
      <p className="mb-4">
        If you're unable to complete a task within the given timeframe, don't worry. We are here to help you finish the task to ensure you successfully earn your certificate.
      </p>
    </section>
  );
}

export default ShippingDeliveryPolicy;
