import React from "react";
import { useEffect } from "react";
const Help = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top
      }, []);
    
    return (
        <div className="lg:w-[60%] w-[90%] mx-auto my-10 min-h-[60vh]">
            <h1 className="text-3xl font-bold text-center mb-6">Help Center for Codisher Internships</h1>
            <p className="mb-4">
                Welcome to the Codisher Help Center. Here you can find answers to common questions about our internship program.
            </p>

            <h2 className="text-2xl font-semibold mt-6">Frequently Asked Questions</h2>

            <h3 className="mt-4 font-semibold">1. How can I apply for an internship at Codisher?</h3>
            <p className="mb-4">You can apply through our website by filling out the internship application form.</p>

            <h3 className="mt-4 font-semibold">2. What qualifications do I need to apply?</h3>
            <p className="mb-4">Qualifications vary by internship position. Please check the specific requirements listed in each job description.</p>

            <h3 className="mt-4 font-semibold">3. How long does the internship last?</h3>
            <p className="mb-4">Internship durations can vary. Please refer to the specific internship posting for details.</p>

            <h3 className="mt-4 font-semibold">4. Who can I contact for more help?</h3>
            <p className="mb-4">For further assistance, please email us at codisherlivelearning@gmail.com</p>

            
        </div>
    );
};

export default Help;
