import React from "react";
import { Link } from "react-router-dom";
// This will take thumbnail , title and url


const BlogCard = ({blog}) =>
{
    console.log(blog.blog_url)
    return (
        
        <Link to={`/blogs/${blog.blog_url.replace('http://codisher.blogspot.com/', '').replace('.html', '')}`}>
        <div>
            <div className="relative w-full max-w-xs h-64 ">
                <img src={blog.blog_thumbnail} alt="Blog Thumbnail" className="w-full h-full object-cover rounded-lg"/>
                <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg"></div>
                <h2 className="absolute bottom-4 left-4 text-white text-xl font-bold z-10">{blog.blog_title}</h2>
            </div>
        </div>
        </Link>
    )
}


export default BlogCard