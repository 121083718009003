import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// Signup.js (Your Signup Component)
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import google_logo from '../public/images/google-logo.png'
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "./GoogleLogin";
import LoaderComponent from "./LoaderComponent";
import header_token from "../Header_Token";
// Signup.js
const MySwal = withReactContent(Swal);
const Signup = () => {
  const [loading ,setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",

  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const { setAuthData } = useAuth();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const validateName = (name) => /^[a-zA-Z\s]+$/.test(name);

  const validateEmail = (email) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  const validatePasswordStrength = (password) => {
    const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&^_-])[A-Za-z\d@$!%*?#&^_-]{8,}$/;
    const moderateRegex = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

    if (strongRegex.test(password)) {
      return "strong";
    } else if (moderateRegex.test(password)) {
      return "moderate";
    } else {
      return "weak";
    }
  };

  const validateConfirmPassword = (password, confirmPassword) =>
    password === confirmPassword;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    let error = "";

    if (name === "name") {
      if (!validateName(value)) {
        error = "Name can only contain alphabets and spaces.";
      } else if (value.trim().length < 2) {
        error = "Name must be at least 2 characters long.";
      }
    }

    if (name === "email") {
      if (!validateEmail(value)) {
        error = "Please enter a valid email address.";
      }
    }

    if (name === "password") {
      const strength = validatePasswordStrength(value);
      if (strength === "weak") {
        error = "Password is too weak.";
      } else if (strength === "moderate") {
        error = "Password is moderate.";
      }
      if (
        formData.confirmPassword &&
        !validateConfirmPassword(value, formData.confirmPassword)
      ) {
        setErrors((prev) => ({
          ...prev,
          confirmPassword: "Passwords do not match.",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          confirmPassword: "",
        }));
      }
    }

    if (name === "confirmPassword") {
      if (!validateConfirmPassword(formData.password, value)) {
        error = "Passwords do not match.";
      }
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameValid =
      validateName(formData.name) && formData.name.trim().length >= 2;
    const emailValid = validateEmail(formData.email);
    const passwordStrength = validatePasswordStrength(formData.password);
    const passwordsMatch = validateConfirmPassword(
      formData.password,
      formData.confirmPassword
    );

    if (
      nameValid &&
      emailValid &&
      (passwordStrength === "strong" || passwordStrength === "moderate") &&
      passwordsMatch
    ) {
      try {
        setLoading(true)
        const payload = {
          name: formData.name,
          email: formData.email,
          password: formData.password,
          cpassword: formData.confirmPassword, // Backend expects 'cpassword'
          verification_url : `${window.location.protocol}//${window.location.host}/verify_email`
        };

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/signup`, payload, {
          headers: {
            "Content-Type": "application/json",
            'X-Frontend-Secret': header_token
          },
        });
       
        if (response.status === 200 || response.status === 201) {
          setLoading(false)
            await MySwal.fire({
                title: 'Success!',
                text: response.data,
                icon: 'success',
                confirmButtonText: 'OK'
              });      
          setFormData({
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
          });
          setErrors({
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
          });
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setLoading(false)
          toast.error(error.response.data);
        } else {
          setLoading(false)
          toast.error("An unexpected error occurred.");
        }
      }
    } else {
      setErrors({
        name: !nameValid ? "Please enter a valid name." : "",
        email: !emailValid ? "Please enter a valid email address." : "",
        password:
          passwordStrength === "weak"
            ? "Password is too weak."
            : passwordStrength === "moderate"
            ? "Password is moderate."
            : "",
        confirmPassword: !passwordsMatch ? "Passwords do not match." : "",
      });
      toast.error("Please enter the correct details in the form.");
    }
  };


  const handleGoogleSuccess = async (response) => {
    const tokenId = response.credential;
    
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/signin_with_google`, 
        { tokenId }, 
        { headers: { 'X-Frontend-Secret': header_token } }
      );
      
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user_email", res.data.user_email);
      
      setAuthData({
        token: res.data.token,
        userEmail: res.data.user_email,
        userName: res.data.user_name
      });
      
      MySwal.fire('Success', 'Logged in successfully!', 'success');
      navigate("/");
    } catch (error) {
      toast.error("Google Sign-In failed");
    }
  };
  const handleError = () => {
    toast.error('Login Failed');
};

  return (
    <div className="flex flex-col items-center justify-center p-4 animated-border mx-auto lg:w-[35%] my-10 rounded-lg shadow-2xl text-white w-[98%]">
       <LoaderComponent isLoading={loading} />
           <h2 className="text-2xl font-semibold text-center mb-6">Signup</h2>
        <div >
           <GoogleLoginButton onSuccess={handleGoogleSuccess} onError={handleError}></GoogleLoginButton>
        </div>
        <div className="flex w-[92%] text-white items-center my-4">
            <div className="bg-white p-[0.5] h-[1px] w-full"></div>
            <p className="px-2 text-xl">OR</p>
            <div className="bg-white p-[0.5] h-[1px] w-full"></div>
        </div>
      <form
        onSubmit={handleSubmit}
        className="w-full rounded-lg shadow-md p-6"
      >
        {/* Name Field */}
        <div className="mb-4">
          <label className="block mb-2 " htmlFor="name">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className={`w-full px-3 py-2 bg-transparent border ${
              errors.name ? "border-red-500" : "border-gray-300"
            } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your full name"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name}</p>
          )}
        </div>

        {/* Email Field */}
        <div className="mb-4">
          <label className="block mb-2 " htmlFor="email">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className={`w-full px-3 py-2 border bg-transparent ${
              errors.email ? "border-red-500" : "border-gray-300"
            } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            value={formData.email}
            onChange={handleChange}
            placeholder="you@example.com"
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email}</p>
          )}
        </div>

        {/* Password Field */}
        <div className="mb-4 relative">
          <label className="block mb-2 " htmlFor="password">
            Password
          </label>
          <div  className={`w-full flex px-3  border bg-transparent ${
                errors.password ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none `}>
                <input
                type={passwordVisible ? "text" : "password"}
                name="password"
                id="password"
                className="w-full border-none bg-transparent outline-none py-2"
                value={formData.password}
                onChange={handleChange}
                placeholder="Create a password"
            />
            <div
                className="inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={togglePasswordVisibility}
            >
                {passwordVisible ? (
                <FaEyeSlash className="text-gray-600 " />
                ) : (
                <FaEye className="text-gray-600 " />
                )}
            </div>
          </div>
            
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">{errors.password}</p>
          )}
          {formData.password && !errors.password && (
            <p
              className={`text-sm mt-1 ${
                validatePasswordStrength(formData.password) === "strong"
                  ? "text-green-500"
                  : "text-yellow-500"
              }`}
            >
              Password strength:{" "}
              {validatePasswordStrength(formData.password).charAt(0).toUpperCase() +
                validatePasswordStrength(formData.password).slice(1)}
            </p>
          )}
        </div>

        {/* Confirm Password Field */}
        <div className="mb-6 relative">
          <label className="block mb-2" htmlFor="confirmPassword">
            Confirm Password
          </label>
            <div  className={`w-full flex px-3 border bg-transparent ${
                errors.confirmPassword ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}>
                <input
                type={confirmPasswordVisible ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                className="w-full border-none bg-transparent outline-none py-2"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Re-enter your password"
            />
            <div
                className="inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
            >
                {confirmPasswordVisible ? (
                <FaEyeSlash className="text-gray-600" />
                ) : (
                <FaEye className="text-gray-600" />
                )}
            </div>
          </div>
         
          {errors.confirmPassword && (
            <p className="text-red-500 text-sm mt-1">
              {errors.confirmPassword}
            </p>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200 font-bold"
        >
          Submit
        </button>
        <div className="flex lg:gap-x-5 mt-4 gap-x-2 lg:text-lg text-sm">
            <p>Already have an account ?</p>
            <Link to="/login" className="text-blue-600">Login</Link>
        </div>
      </form>
    </div>
  );
};

export default Signup;
