import React from "react";
import InternshipBanner from "./Components/InternshipBanner";
import InternshipAbout from "./Components/InternshipAbout";
// This page will take internship_domain_name , internship_id & internship_banner
import InternLearningPath from "./Components/InternLearningPath";
import { useParams } from "react-router-dom";
import InternshipForm from "./Components/internshipForm";
import { useState } from "react";
const InternshipPage = () =>
{
    const { page_id } = useParams(); 
    const [showForm, setShowForm] = useState(false);
    const [internshipData, setInternshipData] = useState({
      internshipDomainName: "",
      internshipDomainId: "",
      userEmail: "",
    });

    const handleApplyClick = (name, id, email) => {
        setInternshipData({
          internshipDomainName: name,
          internshipDomainId: id,
          userEmail: email,
        });
        setShowForm(true); // Open the form
      };
    const closeForm = () => {
        setShowForm(false); // Close the form
      };
    
    return(
        <div className="relative">
                {showForm && (
                    <InternshipForm
                    internshipDomainName={internshipData.internshipDomainName}
                    internshipDomainId={internshipData.internshipDomainId}
                    userEmail={internshipData.userEmail}
                    closeForm={closeForm}
                    />
                )}
                <InternshipBanner page_id = {page_id} onApplyClick={handleApplyClick}/>
                <InternshipAbout/>
                <InternLearningPath/>
        </div>
    )
}


export default InternshipPage