import React, { useEffect, useState } from "react";
import BlogCard from "../Blogs/Components/BlogCard";
import axios from "axios";
import header_token from "../Header_Token";
import LoaderComponent from "../Components/LoaderComponent";  // Import the LoaderComponent

const AllBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [error, setError] = useState(null);     // State for errors
    const [loading, setLoading] = useState(true); // State for loading

    // Function to fetch blogs from API
    const fetchBlogs = async () => {
        setLoading(true); // Start loading
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`, {
                headers: {
                    'X-Frontend-Secret': header_token,  // Add your custom header here
                }
            });

            // Set the blogs state to the blog_meta_data array
            setBlogs(response.data.blog_meta_data);
        } catch (error) {
            console.error("Error fetching blogs:", error);
            setError("Failed to load blogs.");
        } finally {
            setLoading(false); // End loading
        }
    };

    useEffect(() => {
        fetchBlogs();  // Fetch blogs when component mounts
    }, []);

    return (
        <div className="w-[80%] mx-auto py-10 relative">
            {/* Decorative Elements */}
            <div className="absolute lg:w-[20%] lg:h-[20%] lg:left-[20%] h-[40%] w-[40%] left-[5%] rotate-[82.42deg] custom-bg-ilus"></div>
            <div className="absolute lg:w-[20%] lg:h-[20%] lg:right-[10%] lg:bottom-[5%] h-[5%] w-[5%] bottom-[5%] right-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
            <div className="absolute lg:w-[10%] lg:h-[10%] lg:left-[5%] lg:bottom-[5%] w-[30%] h-[30%] bottom-[15%] left-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
            
            {/* Title */}
            <h1 className="text-center text-5xl font-extrabold py-5">Blogs</h1>
            
            {/* Loader Component */}
            <LoaderComponent isLoading={loading} />

            {/* Blogs Grid */}
            {!loading && (
                <div className="flex flex-wrap justify-between lg:py-10 py-5 gap-y-5 lg:gap-x-5 gap-x-0">
                    {blogs.length > 0 ? (
                        blogs.map((blog, index) => (
                            <BlogCard key={index} blog={blog} />  // Pass each blog data to BlogCard
                        ))
                    ) : (
                        <p className="text-center w-full">No blogs available at the moment.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default AllBlogs;
