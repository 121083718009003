// src/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState({
        token: null,
        userEmail: null,
        userName: null, // Ensure consistent naming
    });
    const [authLoading, setAuthLoading] = useState(true); // New state for loading

    useEffect(() => {
        const fetchAuthData = () => {
            const token = localStorage.getItem("token");
            const userEmail = localStorage.getItem("user_email"); // Ensure key consistency
            const userName = localStorage.getItem("user_name"); // Fetch user name if needed

            if (token && userEmail) {
                setAuthData({ token, userEmail, userName });
            } else {
                setAuthData({ token: null, userEmail: null, userName: null });
            }

            setAuthLoading(false); // Authentication check is complete
        };

        fetchAuthData();
    }, []);

    return (
        <AuthContext.Provider value={{ authData, setAuthData, authLoading }}>
            {children}
        </AuthContext.Provider>
    );
};
