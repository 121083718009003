import React, { useState, useEffect } from "react";
import { FaStar, FaStarHalf } from "react-icons/fa";
import MyCarousel from "../../Components/CarouselComponent";
import axios from "axios";
import user_avatar from '../../public/images/user_avtar.png';
import { toast } from "react-toastify"; // Assuming you're using react-toastify for notifications
import header_token from "../../Header_Token";
// ReviewCard component
const ReviewCard = ({ name, message, rating }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const visibleContent = message.slice(0, 150); // Show first 150 characters

  // Helper function to render stars
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    return (
      <div className="flex gap-x-2 text-[#FFD700]">
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={index} />
        ))}
        {halfStar && <FaStarHalf />}
      </div>
    );
  };

  return (
    <div className="bg-[#eeeeee1f] rounded-sm p-5 text-justify w-[90%] mx-auto border-[1px] border-white rounded-lg shadow-2xl">
      <div className="flex gap-x-10 items-center mb-2">
        <div>
          <img src={user_avatar} className="h-10 w-10" alt="User Avatar" />
        </div>
        <div>
          <p>{name}</p>
          {renderStars(rating)}
        </div>
      </div>
      <div>
        <p className="leading-relaxed">
          {isExpanded ? message : `${visibleContent}...`}
        </p>
        <button
          onClick={toggleReadMore}
          className="text-blue-500 hover:underline mt-4"
        >
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      </div>
    </div>
  );
};

// ReviewSection component
const ReviewSection = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch reviews from API
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_review`,{headers:{ 'X-Frontend-Secret': header_token}});
        if (response.status === 200) {
          setReviews(response.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Error loading reviews.");
        }
      } catch (error) {
        setLoading(false);
        toast.error("Failed to fetch reviews.");
      }
    };

    fetchReviews();
  }, []);

  const carouselItems = reviews.map((review, index) => (
    <ReviewCard
      key={index}
      name={review.name}
      message={review.message}
      rating={review.rating}
    />
  ));

  if (loading) {
    return <div>Loading reviews...</div>;
  }

  return (
    <div className="pb-12 relative">
      <div className="absolute lg:w-[20%] lg:h-[20%] lg:left-[20%] h-[40%] w-[40%] left-[5%] rotate-[82.42deg] custom-bg-ilus"></div>
      <div className="absolute lg:w-[20%] lg:h-[20%] lg:right-[10%] lg:bottom-[5%] h-[5%] w-[5%] bottom-[5%] right-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
      <div className="absolute lg:w-[10%] lg:h-[10%] lg:left-[5%] lg:bottom-[5%] w-[30%] h-[30%] bottom-[15%] left-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
      <h1 className="text-5xl font-extrabold text-center my-5 py-10">Reviews</h1>
      <div className="lg:w-[80%] mx-auto w-[95%]">
        <MyCarousel items={carouselItems} />
      </div>
    </div>
  );
};

export default ReviewSection;
