import React, { useState } from "react";

const FAQs = () => {
    const [expanded, setExpanded] = useState(null); // Track which question is expanded

    const toggleExpand = (index) => {
        setExpanded(expanded === index ? null : index); // Toggle expansion of the FAQ item
    };

    const faqData = [
        {
            question: "How do I sign up?",
            answer: "To sign up for an internship, visit our sign-up page, fill in the required information, and submit your application. You will receive a confirmation email once your application is processed."
        },
        {
            question: "How do I log in?",
            answer: "To log in, go to the login page and enter your registered email address and password. If you encounter any issues, please check the 'Forgot Password?' section."
        },
        {
            question: "How do I download my certificate?",
            answer: "Once you have completed your internship, you will receive an email with a link to download your certificate. You can also find it in your profile under the 'Certificates' section."
        },
        {
            question: "Where do I submit my tasks?",
            answer: "You can submit your tasks through the dashboard after logging in. Navigate to the 'Tasks' section, select the relevant task, and upload your submission."
        },
        {
            question: "How do I apply for an internship?",
            answer: "To apply for an internship, go to the internships page, select the internship you are interested in, and click on the 'Apply Now' button. Fill out the application form and submit it."
        },
        {
            question: "What if I forgot my password?",
            answer: "If you forgot your password, click on the 'Forgot Password?' link on the login page. You will receive an email with instructions to reset your password."
        },
        {
            question: "Can I apply for more than one internship in a month?",
            answer: "No, if you have any running internship, you cannot apply for another internship in the same month."
        }
    ];

    return (
        <div className="lg:w-[60%] w-[90%] mx-auto py-10">
            <h1 className="text-center text-4xl font-bold mb-6">Frequently Asked Questions</h1>
            <div className="space-y-4">
                {faqData.map((item, index) => (
                    <div key={index} className="border-b border-gray-300 pb-4">
                        <div
                            className="flex justify-between items-center cursor-pointer"
                            onClick={() => toggleExpand(index)}
                        >
                            <h2 className="text-lg font-semibold">{item.question}</h2>
                            <span className={`transform transition-transform duration-200 ${expanded === index ? 'rotate-180' : ''}`}>
                                ▼
                            </span>
                        </div>
                        {expanded === index && (
                            <p className="mt-2 text-white">{item.answer}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQs;
