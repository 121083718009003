import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoaderComponent from "../Components/LoaderComponent";
import { toast } from "react-toastify";
import header_token from "../Header_Token";

const BlogDetail = () => {
  const { year, month, title } = useParams(); // Get year, month, title from URL params
  const [blog, setBlog] = useState(null);     // State to store blog data
  const [loading, setLoading] = useState(true); // State to manage loading status
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);


 // Fetch blog data from API
const fetchBlog = async () => {
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/${year}/${month}/${title}`,{headers:{'X-Frontend-Secret': header_token,}});
      if (response.status === 200) {
        setBlog(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error loading blog details.");
      }
    } catch (error) {
      setLoading(true);  // Ensure to stop loading in case of error
      if (error.response) {
        // Error is coming from the server
        if (error.response.status === 403) {
          toast.error("Blog details can't be fetched.");
        } else {
          toast.error(`Error: ${error.response.status} - ${error.response.data}`);
        }
      } else {
        // Network or other errors (like CORS or no internet)
        toast.error("Network error. Please try again.");
      }
    }
  };
  

  useEffect(() => {
    fetchBlog(); // Call fetchBlog when the component mounts or URL params change
  }, [year, month, title]);


  // Parse and format the date
  const formattedDate = blog
    ? new Date(blog.published).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    : "";

  // Render the blog content
    if (loading){
        return(
            <div className="min-h-[60vh]">
                    <LoaderComponent isLoading={loading} />;
            </div>
        )     
    }
        

  return (
    <section className="lg:w-[50%] mx-auto text-justify my-10 shadow-2xl px-5 py-10 w-[90%]">
         
      {/* Blog Title */}
      <h1 className="lg:text-3xl text-xl font-bold lg:w-[82%] mx-auto">{blog.title}</h1>

      {/* Published Date */}
      <div className="lg:w-[82%] mx-auto">
        <p className="text-md py-2">Published on: {formattedDate}</p>
      </div>

      {/* Blog Content */}
      <div className="flex justify-center items-center flex-col lg:w-[80%] mx-auto w-[95%]">
        <div dangerouslySetInnerHTML={{ __html: blog.content }} />
      </div>

      {/* Blog Labels */}
      <div className="lg:w-[80%] mx-auto justify-start mt-5">
        {blog.labels && blog.labels.length > 0 ? (
            <ul>
            {blog.labels.map((label, index) => (
                <li key={index} className="inline-block bg-gray-800 text-sm px-2 py-1 rounded-md mr-2">
                {label}
                </li>
            ))}
            </ul>
        ) : null}
        </div>
    </section>
  );
};

export default BlogDetail;
