import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import LoaderComponent from "./LoaderComponent";
import header_token from "../Header_Token";
import Swal from "sweetalert2";

const UpdatePassword = () => {
  const { email,token } = useParams(); // Get the token from the URL params
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    return regex.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Password validation
    if (!validatePassword(formData.password)) {
      setPasswordError(
        "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    } else {
      setPasswordError("");
    }

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    } else {
      setConfirmPasswordError("");
    }

    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/updatepassword`, {
        token: token,
        email:email,
        password: formData.password,
      }, {
        headers: {
          "Content-Type": "application/json",
          'X-Frontend-Secret': header_token,
        },
      });

      if (response.status === 200) {
        Swal.fire('Success','Password updated successfully','success')
        navigate("/login"); // Redirect to login after success
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while updating the password.");
    }
  };

  return (
    <div className="min-h-[60vh]">
    <div className="flex flex-col items-center justify-center p-4 animated-border mx-auto lg:w-[35%] my-10 rounded-lg shadow-2xl text-white w-[98%]">
      <LoaderComponent isLoading={loading} />
      <h2 className="text-2xl font-semibold text-center mb-6">Reset Password</h2>
      <form onSubmit={handleSubmit} className="w-full rounded-lg shadow-md p-6">
        {/* Password Field */}
        <div className="mb-4">
          <label className="block mb-2" htmlFor="password">
            New Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className="w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.password}
            onChange={handleChange}
            placeholder="New password"
          />
          {passwordError && <p className="text-red-500 text-sm mt-1">{passwordError}</p>}
        </div>

        {/* Confirm Password Field */}
        <div className="mb-4">
          <label className="block mb-2" htmlFor="confirmPassword">
            Confirm Password
          </label>
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            className="w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm password"
          />
          {confirmPasswordError && <p className="text-red-500 text-sm mt-1">{confirmPasswordError}</p>}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200 font-bold"
        >
          Reset Password
        </button>
      </form>
    </div>
    </div>
  );
};

export default UpdatePassword;
