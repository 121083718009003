import React, { useState } from "react";
import axios from "axios";
import discuss_illus from '../../public/images/discuss_illus.svg';
import LoaderComponent from "../../Components/LoaderComponent";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import header_token from "../../Header_Token";
const DiscussSection = () => {
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [errors, setErrors] = useState({ email: '', mobile: '' });
  const [loading, setLoading] = useState(false); // State to manage loading status
  // Regex for Gmail validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Regex for Mobile number validation (assuming 10 digits for validation)
  const mobileRegex = /^[0-9]{10}$/;

  // Handle input change for Email
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!emailRegex.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "Please enter a valid Email address." }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    }
  };

  // Handle input change for Mobile number
  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);

    if (!mobileRegex.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, mobile: "Please enter a valid 10-digit mobile number." }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, mobile: '' }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!errors.email && !errors.mobile && email && mobile) {
      try {
        setLoading(true)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/submit_contact_form`, { email, mobile } , {headers:{ 'X-Frontend-Secret': header_token}});
        setMobile('')
        setEmail('')
        setLoading(false)
        Swal.fire("Success","Thanks for connecting with us , Our team will reach you soon.","success")
      } catch (error) {
        setLoading(false)
        console.error(error);
        toast.error("Failed to submit the form.");
      }
    } else {
      setLoading(false)
      toast.error("Please fix the errors before submitting.");
    }
  };

  return (
    <div className="lg:w-[80%] mx-auto py-10 w-[95%] relative">
        <LoaderComponent isLoading={loading}></LoaderComponent>
      <div className="absolute lg:w-[20%] lg:h-[20%] lg:left-[20%] h-[40%] w-[40%] left-[5%] rotate-[82.42deg] custom-bg-ilus"></div>
      <div className="absolute lg:w-[20%] lg:h-[20%] lg:right-[10%] lg:bottom-[5%] h-[5%] w-[5%] bottom-[5%] right-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
      <div className="absolute lg:w-[10%] lg:h-[10%] lg:left-[5%] lg:bottom-[5%] w-[30%] h-[30%] bottom-[15%] left-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
      <h1 className="text-center text-5xl font-extrabold py-5">Contact Us</h1>
      <div className="bg-[#eeeeee1f] rounded-lg border-[1px] border-white flex lg:flex-row justify-between items-center my-5 flex-col">
        <div className="mx-auto">
          <img src={discuss_illus} className="h-[50vh] w-[50vh]" alt="Illustration" />
        </div>
        <div className="lg:w-[40%] py-5">
          <h1 className="text-2xl my-2 font-bold">Discuss Your Idea With Us!</h1>
          <form className="flex flex-col gap-y-5 lg:w-[70%]" onSubmit={handleSubmit}>
            <div>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email Address"
                className="bg-transparent border border-gray-300 p-2 rounded w-full"
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div>
              <input
                type="tel"
                value={mobile}
                onChange={handleMobileChange}
                placeholder="Mobile Number"
                className="bg-transparent border border-gray-300 p-2 rounded w-full"
              />
              {errors.mobile && <p className="text-red-500 text-sm">{errors.mobile}</p>}
            </div>
            <button type="submit" className="contact-card-button p-2 rounded">
              Share with us
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DiscussSection;
