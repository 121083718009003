import React from 'react';

const CancellationRefundPolicy = () => {
  return (
    <section id="cancellation-refund-policy" className="p-8  lg:w-[60%] mx-auto my-10 w-[90%]">
      <h2 className="text-3xl font-bold mb-4 text-center">Cancellation & Refund Policy</h2>
      
      <p className="mb-4">At Codisher, we strive to provide the best experience for all users. Below is our policy on cancellations and refunds:</p>
      
      <h3 className="text-xl font-semibold mb-2">Cancellation Policy</h3>
      <p className="mb-4">
        1. You may cancel your subscription or services anytime by visiting your account settings. <br />
        2. Cancellations made after payment will still grant access to the service until the end of the billing cycle, but no further charges will occur.
      </p>
      
      <h3 className="text-xl font-semibold mb-2">Refund Policy</h3>
      <p className="mb-4">
        1. We offer a 7-day money-back guarantee for first-time subscriptions. If you are unsatisfied, you can request a refund within the first 7 days of your purchase. <br />
        2. After 7 days, no refunds will be provided, and you will retain access until the end of the billing cycle.
      </p>
      
      <h3 className="text-xl font-semibold mb-2">How to Request a Refund</h3>
      <p className="mb-4">
        To request a refund, please contact our support team at <a href="mailto:codisherlivelearning@gmail.com" className="text-blue-500">codisherlivelearning@gmail.com</a> with your order details. Refunds will be processed within 5-7 business days.
      </p>
      
      <h3 className="text-xl font-semibold mb-2">Changes to This Policy</h3>
      <p className="mb-4">
        Codisher reserves the right to modify this policy at any time. Changes will be communicated via email or posted on the website.
      </p>
    </section>
  );
}

export default CancellationRefundPolicy;
