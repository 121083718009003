import React, { useEffect, useState } from "react";
import BlogCard from "../../Blogs/Components/BlogCard";
import axios from "axios";
import header_token from "../../Header_Token";
import LoaderComponent from "../../Components/LoaderComponent";  // Import the LoaderComponent
import { Link } from "react-router-dom";
import { toast } from "react-toastify"; // Import toast for notifications

const BlogSection = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading

    // Function to fetch blogs from API
    const fetchBlogs = async () => {
        setLoading(true); // Start loading
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`, {
                headers: {
                    'X-Frontend-Secret': header_token,  // Add your custom header here
                }
            });

            // Safely set the blogs state to the blog_meta_data array or an empty array if undefined
            const fetchedBlogs = response.data.blog_meta_data || []; // Fallback to an empty array if undefined
            setBlogs(fetchedBlogs);

            // Show a toast if there are no blogs
            if (fetchedBlogs.length === 0) {
                toast.info("No blogs available at the moment."); // Use toast for no blogs message
            }
        } catch (error) {
            console.error("Error fetching blogs:", error);
            toast.error("Failed to load blogs."); // Show toast for error
        } finally {
            setLoading(false); // End loading
        }
    };

    useEffect(() => {
        fetchBlogs();  // Fetch blogs when component mounts
    }, []);

    return (
        <div className="w-[80%] mx-auto py-10 relative">
            {/* Decorative Elements */}
            <div className="absolute lg:w-[20%] lg:h-[20%] lg:left-[20%] h-[40%] w-[40%] left-[5%] rotate-[82.42deg] custom-bg-ilus"></div>
            <div className="absolute lg:w-[20%] lg:h-[20%] lg:right-[10%] lg:bottom-[5%] h-[5%] w-[5%] bottom-[5%] right-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
            <div className="absolute lg:w-[10%] lg:h-[10%] lg:left-[5%] lg:bottom-[5%] w-[30%] h-[30%] bottom-[15%] left-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
            
            {/* Title */}
            <h1 className="text-center text-5xl font-extrabold py-5">Blogs</h1>
            
            {/* Loader Component */}
            {/* <LoaderComponent isLoading={loading} /> */}

            {/* Blogs Grid */}
            {!loading && (
                <div className="flex flex-wrap justify-between lg:py-10 py-5 gap-y-5 lg:gap-x-5 gap-x-0">
                    {blogs.length > 0 ? (
                        blogs.slice(0, 4).map((blog, index) => ( // Use slice to get the first 4 items
                            <BlogCard key={index} blog={blog} />  // Pass each blog data to BlogCard
                        ))
                    ) : null} {/* Don't show any message if no blogs are available */}
                </div>            
            )}
            
            {/* Read More Button */}
            <Link to="/blogs">
                <div className="mx-auto w-fit py-4 bg-gradient-to-br from-[#1d8af7] via-[#1d8af7] via-50% to-[#f7861d] shadow-[1px_-1px_8px_0_rgba(247,134,29,0),-1px_1px_8px_0_rgba(29,138,247,0)] transition-all duration-300 ease-linear text-[#f7fafc] font-bold text-center text-lg px-8 rounded-lg py-1 hover:shadow-[0px_4px_15px_0px_rgba(29,138,247,0.6),0px_-4px_15px_0px_rgba(247,134,29,0.6)] hover:scale-105 cursor-pointer">
                    <p>Read More Blogs ....</p>
                </div>
            </Link>
        </div>
    );
};

export default BlogSection;
