import React from "react";
import footer_logo from "../public/images/codisher_logo_3.png"
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () =>
{
    return(
        <div>
           <div className="lg:w-[80%] mx-auto flex lg:flex-row flex-col-reverse shadow-2xl justify-between py-10 w-[95%] gap-y-5 lg:gap-y-0 px-5 bottom-0 items-center">
  <div className="flex flex-col items-center lg:items-start gap-y-10 border-b-[1px] border-white py-5 lg:border-0 w-full lg:px-10 px-2">
    <Link to="/">
      <div className="flex md:flex-col xl:flex-row items-center gap-x-4 w-full">
        <img src={footer_logo} className="h-16 w-16" />
        <p className="text-5xl font-bold">Codisher</p>
      </div>
    </Link>

    <div className="w-full py-5">
      <div className="flex justify-between lg:justify-evenly ">
        <a href="https://www.instagram.com/codisher_live_learning/" className="hover:text-blue-500">
        <FaInstagram className="w-8 h-8" />
        </a>
      <a href="https://www.linkedin.com/company/codisher-live-learning/" className="hover:text-blue-500">
      <FaLinkedin className="w-8 h-8" />
      </a>
        <a href="https://www.instagram.com/codisher_live_learning/" className="hover:text-blue-500">
        <FaFacebook className="w-8 h-8" />
        </a>
        
      </div>
    </div>
  </div>

  <div className="flex flex-col text-xl gap-y-5 border-b-[1px] border-white py-5 lg:border-0 w-full lg:px-5">
    <p className="text-2xl font-bold text-blue-600">Quick Links</p>
    <Link to="/contact" className="hover:text-blue-500">Contact Us</Link>
    <Link to="/about" className="hover:text-blue-500">About Us</Link>
    <Link to="/blogs" className="hover:text-blue-500">Blogs</Link>
    <Link to="/dashboard" className="hover:text-blue-500">Dashboard</Link>
  </div>

  <div className="flex flex-col text-xl gap-y-5 border-b-[1px] border-white py-5 w-full lg:border-0 lg:px-5">
    <p className="text-2xl font-bold text-blue-600">Important Links</p>
    <Link to="/terms_and_conditions" className="hover:text-blue-500">Terms & Conditions</Link>
    <Link to="/cancellation_and_refund" className="hover:text-blue-500">Refund & Cancellation</Link>
    <Link to="/privacy_policy" className="hover:text-blue-500">Privacy</Link>
    <Link to="/shipping_and_delivery" className="hover:text-blue-500">Shipping & Delivery</Link>
  </div>

  <div className="flex flex-col text-xl gap-y-5 border-b-[1px] border-white py-5 w-full lg:border-0 lg:px-5">
    <p className="text-2xl font-bold text-blue-600">Internships</p>
    <Link to="/internship/frontend_development" className="hover:text-blue-500">Frontend Developer</Link>
    <Link to="/internship/backend_development" className="hover:text-blue-500">Backend Developer</Link>
    <Link to="/internship/python_development" className="hover:text-blue-500">Python Developer</Link>
    <Link to="/internship/flutter_development" className="hover:text-blue-500">Flutter Developer</Link>
  </div>
</div>

        </div>
    )
}


export default Footer