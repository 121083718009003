import React from "react";


const InternLearningPath = () =>
{
    return(
    <div class="my-24 space-y-14 ">
      <p class="color-primary text-4xl font-extrabold text-center mb-10">
        Learning Path
      </p>
      <div class="flex items-center justify-center flex-col md:flex-row gap-10 md:gap-0">
        <div class="md:space-y-2 flex flex-col items-center">
          <div
            class=" border-primary border-[6px] rounded-3xl h-20 w-20 1.2px [transform:_rotate(41.36deg)] flex justify-center items-center">
            <div class="bg-dark p-3 h-12 w-12 rounded-2xl flex justify-center items-center">
              <p class="text-xl font-extrabold text-white [transform:_rotate(-41.36deg)]">
                1
              </p>
            </div>
          </div>
          <p class="mt-2 ">Beginner</p>
        </div>

        <p class="-mt-12 hidden md:flex">
          ....................................................
        </p>
        <p  class="-mt-10 sm:hidden ">
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
        </p>

        <div class="space-y-3 flex flex-col items-center -mt-5">
          <div
            class="bg-whitesmoke border-primary border-[6px] rounded-3xl h-20 w-20 1.2px [transform:_rotate(41.36deg)] flex justify-center items-center">
            <div class="bg-dark p-3 h-12 w-12 rounded-2xl flex justify-center items-center">
              <p class="text-xl font-extrabold text-white [transform:_rotate(-41.36deg)]">
                2
              </p>
            </div>
          </div>
          <p>Intermediate</p>
        </div>
        <p class="-mt-12 hidden md:flex">
          ....................................................
        </p>
        <p  class="-mt-10 sm:hidden">
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
          .
          <br/>
        </p>

        <div class="space-y-3 flex flex-col items-center -mt-5">
          <div
            class="bg-whitesmoke border-primary border-[6px] rounded-3xl h-20 w-20 1.2px [transform:_rotate(41.36deg)] flex justify-center items-center">
            <div class="bg-dark p-3 h-12 w-12 rounded-2xl flex justify-center items-center">
              <p class="text-xl font-extrabold text-white [transform:_rotate(-41.36deg)]">
                3
              </p>
            </div>
          </div>
          <p>Advanced</p>
        </div>
      </div>
    </div>
    )
}

export default InternLearningPath