import React from "react";
import internship_info from "../../public/images/internship_info.jpg"
import { CiMemoPad } from "react-icons/ci";
import { GrCertificate } from "react-icons/gr";
import { LuMonitorDot } from "react-icons/lu";
import { FaTasks } from "react-icons/fa";
import { GoProjectSymlink } from "react-icons/go";
import { PiTarget } from "react-icons/pi";


const InternshipAbout = () =>
{
    return(
        <div className="lg:w-[80%] mx-auto relative">
             <div className="absolute lg:w-[20%] lg:h-[20%] lg:left-[20%] h-[40%] w-[40%] left-[5%]  rotate-[82.42deg] custom-bg-ilus"></div>
            <div className="absolute lg:w-[20%] lg:h-[20%] lg:right-[10%] lg:bottom-[5%] h-[5%] w-[5%] bottom-[5%] right-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
            <div className="absolute lg:w-[10%] lg:h-[10%] lg:left-[5%]   lg:bottom-[5%] w-[30%] h-[30%] bottom-[15%] left-[5%] rotate-[82.42deg] custom-bg-ilus -z-45"></div>
            <div>
                <p className="lg:text-5xl text-3xl font-bold text-center">About this Internship</p>
                    <div className="">
                        <p className="py-10 text-xl leading-10 text-justify px-3">
                        Codisher, an MSME-registered company, offers a unique 4-week internship that combines practical experience with personalized mentorship. 
                        You'll have the chance to work on real-world projects,
                         gaining invaluable skills and industry knowledge that will set you apart in your career.
                          From coding challenges to innovative problem-solving, Codisher provides continuous support to help you complete each task successfully. 
                          Our goal is to empower interns by fostering creativity, technical growth, and professional development, 
                          ensuring you leave the program not just with experience, but with confidence and a portfolio to showcase your work.
                        </p>
                  </div>

                  <p className="lg:text-5xl  text-3xl font-bold text-center">What will you get ?</p>
                  <div className="grid lg:grid-cols-3 grid-cols-2 lg:gap-10 lg:pl-[10%] my-16 gap-2 px-2 gap-y-5 pl-[10%] lg:pl-0 ">
                            <div className="flex gap-x-2 items-center">
                               <CiMemoPad className="lg:h-10 lg:w-10 w-7 h-7"/>
                               <p className="lg:text-2xl text-md">Offer Letter</p>
                            </div>

                            <div className="flex gap-x-2 items-center">
                               <GrCertificate className="lg:h-10 lg:w-10 w-7 h-7"/>
                               <p className="lg:text-2xl text-md">Certificate of Internship</p>
                            </div>

                            <div className="flex gap-x-2 items-center">
                               <LuMonitorDot className="lg:h-10 lg:w-10 w-7 h-7"/>
                               <p className="lg:text-2xl text-md">Live Session</p>
                            </div>

                            <div className="flex gap-x-2 items-center">
                                <FaTasks className="lg:h-10 lg:w-10 w-7 h-7"/>
                               <p className="lg:text-2xl text-md">Task based learning</p>
                            </div>

                            <div className="flex gap-x-2 items-center">
                               <GoProjectSymlink className="lg:h-10 lg:w-10 w-7 h-7" />
                               <p className="lg:text-2xl text-md">Project based Learning</p>
                            </div>

                            <div className="flex gap-x-2 items-center">
                               <PiTarget className="lg:h-10 lg:w-10 w-7 h-7" />
                               <p className="lg:text-2xl text-md">Doubt Clearing</p>
                            </div>
                  </div>
               
            </div>
        </div>
    )
}

export default InternshipAbout