import React from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const { setAuthData } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear local storage
        localStorage.removeItem('token');
        localStorage.removeItem('user_email');

        // Reset auth data in context
        setAuthData(null); // or set to initial state

        // Redirect to login page
        navigate('/login');
    };

    return (
        <button onClick={handleLogout} className="logout-button w-full">
            Logout
        </button>
    );
};

export default Logout;
