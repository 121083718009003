import React from "react";
import internship_card_illus from '../../public/images/internship_card_illus.svg'
import { Link } from "react-router-dom";
// The card will take image , title and url path
const InternshipCard = ({internship_thumbnail , internship_page_id , internship_id , internship_name}) =>
{
    return(
            <div>
                <Link to={`internship/${internship_page_id}`}>             
                    <div className="internship_card_border hover:shadow-[0px_4px_15px_0px_rgba(29,138,247,0.6),0px_-4px_15px_0px_rgba(247,134,29,0.6)] hover:scale-105 cursor-pointer">
                         <div>
                            <img src= {`${process.env.REACT_APP_API_URL}${internship_thumbnail}`} alt="Internship Card" className="w-80 h-64 object-cover"></img>
                         </div>
                         <div>
                            <p className="text-xl font-bold text-center py-2">{internship_name}</p>
                         </div>
                    </div>
                    </Link>  
            </div>
    )
}

export default InternshipCard