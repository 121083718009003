import React from 'react';
import { Helmet } from 'react-helmet';
import codisher_logo from '../public/images/codisher_logo_3.png'
const OfferLetter = ({ certificateData }) => {
  // Check if data is still being loaded
  if (!certificateData) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container bg-white text-black relative" style={{ border: '1px solid rgb(17, 0, 92)' }}>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <img
        src={codisher_logo} // Path to your logo
        alt="Watermark"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-15 w-1/3 z-1"
      />
      {/* Background images */}
      <div className="absolute top-0 left-0 z-0">
        <img src={`${process.env.REACT_APP_API_URL}/public/images/certificate_left_corner.png`} className="h-[60%] w-[80%]" alt="left corner" />
      </div>
      <img src={`${process.env.REACT_APP_API_URL}/public/images/right_croner_certifcate.png`} className="absolute top-0 right-0 z-0 h-[35%] w-[35%]" alt="right corner" />
      <div className="absolute bottom-0 right-0 z-0 rotate-180">
        <img src={`${process.env.REACT_APP_API_URL}/public/images/certificate_left_corner.png`} className="h-[60%] w-[80%]" alt="left corner bottom" />
      </div>
      <img src={`${process.env.REACT_APP_API_URL}/public/images/right_croner_certifcate.png`} className="absolute bottom-0 left-0 z-0 rotate-180 h-[35%] w-[35%]" alt="right corner bottom" />
      <img src={`${process.env.REACT_APP_API_URL}/public/images/msme-logo.png`} className="absolute top-[50px] right-[50px] z-0 w-[8rem] h-[6rem]" alt="msme logo" />

      {/* Content section */}
      <div className="absolute z-10 w-full top-0 left-0 flex flex-col items-center" style={{ marginTop: '15%' }}>
        <img src={`${process.env.REACT_APP_API_URL}/public/images/codisher_logo.png`} className="w-[10rem] h-[10rem]" alt="codisher logo" />
        <h2 className="text-2xl font-bold mt-7">OFFER LETTER</h2>
      </div>

      <div className="absolute z-20 w-full" style={{ top: '35%', paddingLeft: '35px', paddingRight: '35px' }}>
        <div className="flex justify-between">
          <p className="font-bold">Date: {certificateData.date}</p>
          <p className="font-bold">CD/OL/{certificateData.offer_letter_id}</p>
        </div>
        <div className="mt-10">
          <p>Dear <span className="font-bold">{certificateData.name}</span>,</p>
          <p>We are pleased to offer you an internship opportunity from CODISHER as a <span className="font-bold">{certificateData.internship_name}</span> Intern. We believe this internship will provide valuable experience and skills for your career goals.</p>
          <br></br>
          <p>Your internship will focus on learning new skills, hands-on application, and understanding concepts. Our team is confident you will perform your duties to the best of your ability.</p>
          <p>We look forward to a fruitful association. Wishing you a meaningful internship program!</p>
        </div>
      </div>

      {/* Stamps and QR code */}
      <div className="absolute bottom-[10%] left-[10%] z-20">
        <img src={`${process.env.REACT_APP_API_URL}/public/images/certificate_stamp_final.png`} className="h-[8rem] w-[8rem]" alt="stamp" />
      </div>
      <div className="absolute bottom-[7%] right-[15%] z-20">
        <img src={`${process.env.REACT_APP_API_URL}${certificateData.internship_qr_code}`} className="h-[10rem] w-[10rem]" alt="qr code" />
      </div>
    </div>
  );
};

export default OfferLetter;
