import React from "react";
import { useEffect } from "react";
const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top
      }, []);
    
    return (
        <div className="lg:w-[60%] mx-auto my-10 w-[90%]">
            <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy for Codisher Internships</h1>
            <p className="mb-4">
                At Codisher Internship, we are committed to protecting the privacy and security of our interns' information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you participate in our internship program.
            </p>

            <h2 className="text-2xl font-semibold mt-6">Information Collection</h2>
            <h3 className="mt-4 font-semibold">1. Personal Identification Information</h3>
            <p className="mb-4">
                We may collect personal identification information, including but not limited to, name, email address, mailing address, phone number, and academic background.
            </p>

            <h3 className="mt-4 font-semibold">2. Usage and Log Data</h3>
            <p className="mb-4">
                Information about how you access and use our services, including log files and device information.
            </p>

            <h2 className="text-2xl font-semibold mt-6">Use of Information</h2>
            <p className="mb-4">
                The information we collect is used for the following purposes:
                <ul className="list-disc list-inside ml-5">
                    <li>
                        <strong>Program Administration:</strong> To manage and administer the internship program, including communications and logistics.
                    </li>
                    <li>
                        <strong>Improvement of Services:</strong> To enhance our program offerings and personalize your experience.
                    </li>
                    <li>
                        <strong>Compliance and Safety:</strong> To comply with legal obligations and ensure the safety of our participants.
                    </li>
                </ul>
            </p>

            <h2 className="text-2xl font-semibold mt-6">Sharing of Information</h2>
            <p className="mb-4">
                We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
            </p>

            <h2 className="text-2xl font-semibold mt-6">Data Security</h2>
            <p className="mb-4">
                We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information and data stored on our site.
            </p>

            <h2 className="text-2xl font-semibold mt-6">Your Rights</h2>
            <p className="mb-4">
                You have the right to access, correct, or delete your personal information. If you wish to exercise these rights, please contact us at our provided contact details.
            </p>

            <h2 className="text-2xl font-semibold mt-6">Changes to This Privacy Policy</h2>
            <p className="mb-4">
                Codisher Internship has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage users to frequently check this page for any changes to stay informed about how we are protecting the personal information we collect.
            </p>

            <h2 className="text-2xl font-semibold mt-6">Acceptance of These Terms</h2>
            <p className="mb-4">
                By enrolling in the Codisher Internship, you signify your acceptance of this policy. If you do not agree to this policy, please do not enroll in our program.
            </p>

            <h2 className="text-2xl font-semibold mt-6">Contacting Us</h2>
            <p>If you have any questions about this Privacy Policy, the practices of Codisher Internship, or your dealings with this program, please contact us at:</p>
            <p>Email: codisherlivelearning@gmail.com</p>

            <p className="mt-6">This document was last updated on 11/10/2024.</p>
        </div>
    );
};

export default PrivacyPolicy;
