
import './App.css';
import Header from './Components/Header';
import Home from './Home/Home';
import Footer from './Components/Footer';
import AboutPage from './About/AboutPage';
import ContactPage from './Contact/ContactPage';
import BlogsPage from './Blogs/BlogsPage';
import InternshipPage from './Internship/InternshipPage';
import {BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Signup from './Components/Signup';
import Login from './Components/Login';
import Dashboard from './Dashboard/Dashboard';
import VerifyEmail from './Components/VerifyEmail';
import OfferLetterPdf from './Components/OfferLetterPdf';
import CertificatePdf from './Components/CertificatePdf';
import NotFound from './Components/404';
import ForgotPassword from './Components/ForgotPassword';
import UpdatePassword from './Components/Update_Password';
import BlogDetail from './Blogs/DetailBlog';
import TermsAndConditions from './Documents/TNC';
import Help from './Documents/Help';
import PrivacyPolicy from './Documents/Privacy';
import FAQs from './Documents/FAQ';
import CancellationRefundPolicy from './Documents/Refund';
import ShippingDeliveryPolicy from './Documents/Shipping';
import QuickApply from './Internship/QuickApply';
import InternshipDetails from './Components/VerifyIntern';

function App() {
  return (
    <Router>
    <div className='bg-[#1E2227] text-white'>
      <Header></Header>
              <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"/>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/internship/:page_id" element={<InternshipPage/>}/>
        <Route path="/signup"element={<Signup/>} />   
        <Route path="/login" element={<Login/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/verify_email/:email/:token" element={<VerifyEmail/>}/>
        <Route path="/offer_letter/:email/:internship_id" element={<OfferLetterPdf/>}></Route>
        <Route path="/certificate/:email/:internship_id" element={<CertificatePdf/>}></Route>
        <Route path="/forgot_password" element={<ForgotPassword/>}></Route>
        <Route path="/update_password/:email/:token" element={<UpdatePassword/>}></Route>
        <Route path="/blogs/:year/:month/:title" element={<BlogDetail/>}></Route>
        <Route path='/terms_and_conditions' element={<TermsAndConditions/>}></Route>
        <Route path='privacy_policy' element={<PrivacyPolicy/>}></Route>
        <Route path="help" element={<Help/>}></Route>
        <Route path='/faqs' element={<FAQs></FAQs>}></Route>
        <Route path='/cancellation_and_refund' element={<CancellationRefundPolicy/>}></Route>
        <Route path='/shipping_and_delivery' element={<ShippingDeliveryPolicy/>}></Route>
        <Route path='/quick_apply' element={<QuickApply/>}></Route>
        <Route path='/verify_intern/:email/:internship_id' element={<InternshipDetails/>}></Route>
        <Route path="*" element={<NotFound/>}></Route>

      </Routes>
      <Footer></Footer>
    </div>
  </Router>
  );
}

export default App;

