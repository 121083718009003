// LoaderComponent.js
import React from 'react';

const LoaderComponent = ({ isLoading }) => {
  if (!isLoading) return null; // Return nothing if loading is false

  return (
        <div id="loader" className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center z-50 pointer-none">
            <div className=" animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
        </div>
  );
};

export default LoaderComponent;
