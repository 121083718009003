import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import header_token from '../Header_Token';
const InternTaskForm = ({ internship_id, user_mail, onClose }) => {
  const [formData, setFormData] = useState({
    github_url_task1: '',
    github_url_task2: '',
    linkedin_url_task1: '',
    linkedin_url_task2: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      user_mail, // assuming user_mail is passed from parent
      internship_id,
      internship_task1_github_url: formData.github_url_task1,
      internship_task2_github_url: formData.github_url_task2,
      internship_task1_linkedin_url: formData.linkedin_url_task1,
      internship_task2_linkedin_url: formData.linkedin_url_task2,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/internship/submit_intern_task`,
        requestData,
        {headers:{'X-Frontend-Secret': header_token}}
      );
      if (response.status === 200) {
        await Swal.fire({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            confirmButtonText: 'OK',
          });
        onClose(); // Close the form after successful submission
      }
    } catch (error) {
      toast.error('Failed to submit tasks. Please try again.');
      console.error(error);
    }
  };

  return (
    <div
      id="contactUs"
      style={{
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))',
      }}
      className="w-[100vw] h-[100vh] flex h-fit fixed overflow-y-scroll z-30 overflow-hidden"
    >
      <div className="lg:w-[70%] w-[95%] relative m-3 gap-3 mx-auto bg-black p-[1.5rem] rounded-[1.25rem] shadow-md z-30">
        <p
          className="absolute top-7 right-5 w-6 text-xl font-bold cursor-pointer"
          alt="cross icon"
          id="closeForm"
          onClick={onClose}
        >
          X
        </p>

        <div className="mt-5">
          <form className="space-y-2 flex flex-col" onSubmit={handleSubmit}>
            <input type="hidden" name="internship_id" value={internship_id} />
            <input type="hidden" name="user_mail" value={user_mail} />

            <label>Task 1 Github URL</label>
            <input
              type="text"
              className="p-2 border-2 border-gray-800 bg-transparent"
              name="github_url_task1"
              value={formData.github_url_task1}
              onChange={handleChange}
              required
            />

            <label>Task 2 Github URL</label>
            <input
              type="text"
              className="p-2 border-2 border-gray-800 bg-transparent"
              name="github_url_task2"
              value={formData.github_url_task2}
              onChange={handleChange}
              required
            />

            <label>Task 1 LinkedIn URL</label>
            <input
              type="text"
              className="p-2 border-2 border-gray-800 bg-transparent" 
              name="linkedin_url_task1"
              value={formData.linkedin_url_task1}
              onChange={handleChange}
              required
            />

            <label>Task 2 LinkedIn URL</label>
            <input
              type="text"
              className="p-2 border-2 border-gray-800 bg-transparent"
              name="linkedin_url_task2"
              value={formData.linkedin_url_task2}
              onChange={handleChange}
              required
            />

            <button className="bg-yellow-600 p-2 rounded-lg" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InternTaskForm;
