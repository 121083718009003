// MyCarousel.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Carousel.css';

// Custom Dot Component
const CustomDot = ({ onClick, active }) => {
  return (
    <button
      onClick={onClick}
      style={{
        width: '10px',
        height: '12px',
        borderRadius: active ? '25%' : '50%',
        backgroundColor: active ? '#FA7C0B' : '#ccc',
        margin:"10px",
        border: 'none',
        outline: 'none',
        transition: 'width 0.5s ease'
      }}
    />
  );
};

// Main Carousel Component
const MyCarousel = ({ items }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={1000}
      showDots={true}
      arrows={false}
      customDot={<CustomDot />}
    >
      {items.map((item, index) => (
        <div className="item" key={index} style={{paddingBottom:"10%"}}>
          {item}
        </div>
      ))}
    </Carousel>
  );
};

export default MyCarousel;
