import React, { useState } from "react";
import paymentQr from "../../public/images/payment_qr.jpg"; // Adjust the path
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import LoaderComponent from "../../Components/LoaderComponent";
import header_token from "../../Header_Token";
const InternshipForm = ({ internshipDomainName, internshipDomainId, userEmail, closeForm }) => {
  const [transactionId, setTransactionId] = useState("");
  const [paymentApp, setPaymentApp] = useState("");
  const [loading ,setLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Submit the form data
    try {
      const data = {
        course_name: internshipDomainName,
        course_id: internshipDomainId,
        transaction_id: transactionId,
        payment_application: paymentApp,
        user_email: userEmail
      };
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/internship/add_intern`, data,{headers: {
        'X-Frontend-Secret': header_token}});
      setLoading(false)
      await Swal.fire({
        title: 'Success!',
        text: response.data.message,
        icon: 'success',
        confirmButtonText: 'OK',
        footer: '<a href="/dashboard">Dashboard</a>'
      });
      closeForm()
    } catch (error) {
      setLoading(false)
      toast.error("Error submitting the form.");
    }
  };

  return (
    <div className="fixed z-50 h-[100vh] overflow-y-auto top-7 pointer-none w-[100%] lg:top-24">
  <LoaderComponent isLoading={loading} />
  <div className="max-h-[100vh] overflow-y-auto">
  <div
    className="lg:w-[40%] w-[95%]  bg-[#1E2227] animated-border relative gap-3 mx-auto p-[1.5rem] rounded-[1.25rem] shadow-md "
  >
    <p className="absolute top-5 right-5 w-6 text-2xl font-bold cursor-pointer" onClick={closeForm}>X</p>
    <div className="mt-5">
      <form className="space-y-2 flex flex-col" onSubmit={handleSubmit} id="internship_form">
        <img
          src={paymentQr}
          className="mx-auto h-[20rem] w-[20rem] mt-5"
          alt="payment qr code"
        />
        <p className="text-red-600">
          Pay &#8377; 89 and submit the Transaction ID and name of Payment application
        </p>
        <a href="/contact">
          Having any issue? <span className="text-blue-500">Contact us</span>
        </a>
        <input type="hidden" name="course_name" value={internshipDomainName} className="bg-transparent" />
        <input type="hidden" name="course_id" value={internshipDomainId} className="bg-transparent" />
        <input type="hidden" name="user_email" value={userEmail} className="bg-transparent" />
        
        <label>Payment Application</label>
        <input
          type="text"
          className="p-2 border rounded border-white bg-transparent"
          name="payment_application"
          required
          placeholder="E.g. PhonePe, Google Pay, BharatPe, Paytm, etc."
          value={paymentApp}
          onChange={(e) => setPaymentApp(e.target.value)}
        />
        
        <label>Transaction ID</label>
        <input
          type="text"
          className="p-2 border bg-transparent border-white rounded"
          name="transaction_id"
          required
          value={transactionId}
          onChange={(e) => setTransactionId(e.target.value)}
        />
        
        <button className="bg-yellow-600 p-2 rounded-lg" type="submit">
          Submit
        </button>
      </form>
    </div>
  </div>
  </div>

</div>

  );
};

export default InternshipForm;
