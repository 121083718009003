import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useEffect } from "react";
// Signup.js (Your Signup Component)
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import LoaderComponent from "../Components/LoaderComponent";
import header_token from "../Header_Token";
// Signup.js
const MySwal = withReactContent(Swal);
const QuickApply = () => {
    const [internships, setInternships] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        course_id: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
    });

    useEffect(() => {
        const fetchInternships = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/internship`, {
                    headers: {
                        'X-Frontend-Secret': header_token,
                    },
                });
                setInternships(response.data);
            } catch (err) {
                toast.error(err.message);
            }
        };

        fetchInternships();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        let error = "";
        if (name === "name" && !/^[a-zA-Z\s]+$/.test(value)) {
            error = "Name can only contain alphabets and spaces.";
        }

        if (name === "email" && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
            error = "Please enter a valid email address.";
        }

        setErrors({
            ...errors,
            [name]: error,
        });
    };

    const handleSelectChange = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectedInternship = internships[selectedIndex];

        setFormData({
            ...formData,
            course_id: selectedInternship.courseID,
            name: selectedInternship.name,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const nameValid = /^[a-zA-Z\s]+$/.test(formData.name) && formData.name.trim().length >= 2;
        const emailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email);

        if (nameValid && emailValid) {
            try {
                setLoading(true);
                const payload = {
                    user_name: formData.name,
                    user_email: formData.email,
                    course_id: formData.course_id
                };
            } catch (error) {
                setLoading(false);
                toast.error(error.response?.data || "An unexpected error occurred.");
            }
        } else {
            setErrors({
                name: !nameValid ? "Please enter a valid name." : "",
                email: !emailValid ? "Please enter a valid email address." : "",
            });
            toast.error("Please enter correct details in the form.");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center p-4 animated-border mx-auto lg:w-[35%] my-10 rounded-lg shadow-2xl text-white w-[98%]">
            <LoaderComponent isLoading={loading} />
            <h2 className="text-2xl font-semibold text-center mb-6">Quick Apply</h2>
            <form onSubmit={handleSubmit} className="w-full rounded-lg shadow-md p-6">
                <div className="mb-4">
                    <label className="block mb-2" htmlFor="name">Name</label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className={`w-full px-3 py-2 bg-transparent border ${
                            errors.name ? "border-red-500" : "border-gray-300"
                        } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your full name"
                    />
                    {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                </div>

                <div className="mb-4">
                    <label className="block mb-2" htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        className={`w-full px-3 py-2 border bg-transparent ${
                            errors.email ? "border-red-500" : "border-gray-300"
                        } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="you@example.com"
                    />
                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                </div>

                <div>
                    <label className="block mb-2" htmlFor="internship_domain">Internship Domain</label>
                    <select
                        id="internship_domain"
                        name="internship_domain"
                        className="bg-black w-full py-2 my-2 border rounded px-2"
                        onChange={handleSelectChange}
                    >
                        {internships.map((internship, index) => (
                            <option key={index} value={internship.courseID}>
                                {internship.name}
                            </option>
                        ))}
                    </select>
                </div>

                <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200 font-bold">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default QuickApply;
